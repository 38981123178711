<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <search-form></search-form>
  </v-container>
</template>


<script>
import SearchForm from '../tecdoc/SearchForm.vue';

import {
  //VBtn,
  //VCard,
  //VCardActions,
  //VCardText,
  //VCol,
  VContainer,
  //VForm,
  //VRow,
  //VSpacer,
  //VTextField,
} from "vuetify/lib";

export default {
  components: {
    //VBtn,
    //VCard,
    //VCardActions,
    //VCardText,
    //VCol,
    VContainer,
    //VForm,
    //VRow,
    //VSpacer,
    //VTextField,
    SearchForm,
  }

  // data() {
  //   return {
  //       articles: [],
  //   };
  // }


};
</script>

<style scoped>

</style>
